import { AssignedConversation, CallState, CallStateChangedMessage } from "@doktor-se/bones-ui/dist/web-shared/types"

import { endCall } from "api"
import { Thunk } from "lib/hooks"
import { simpleConversationSelectors, updateCallState } from "reducers/conversations/conversations.reducer"
import { filterActiveConversations } from "reducers/conversations/utils/filter"

let previousMessageId: number = 0

const handleOtherCalls =
  (callState: CallState, conversation: AssignedConversation): Thunk =>
  dispatch => {
    switch (callState) {
      case "incoming":
        dispatch(endCall(conversation, "busy"))
        break
      default:
        break
    }
  }

const handleCall =
  (message: CallStateChangedMessage): Thunk =>
  (dispatch, getState) => {
    const list = simpleConversationSelectors.selectAll(getState().conversations.conversations)
    const active = filterActiveConversations(list, getState().auth.user)
    const { conversationId, callState } = getState().tokbox
    const conversation = active.find(conversation => conversation.id === message.conversationId)

    if (previousMessageId !== message.id) {
      if (conversation && conversationId && ["active", "outgoing"].includes(callState)) {
        dispatch(handleOtherCalls(message.data.callState, conversation))
      }

      dispatch(updateCallState(message))
      previousMessageId = message.id || 0
    }
  }

export default handleCall
