import { useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { AssignedConversation, LocalConversationState } from "@doktor-se/bones-ui/dist/web-shared/types"

import { featureFlags } from "config"
import { useSelectedConversationAmplitudeLogger } from "lib/amplitude/hooks"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setDetailsPanelState } from "reducers/app"
import { selectConversation } from "reducers/conversations/conversations.reducer"
import { updateLocalConversationState } from "reducers/selected"

import Journal from "components/journal/Journal"
import VerticalTab from "components/VerticalTab/VerticalTab"
import PatientInfo from "pages/patientInfo/PatientInfo"

import PatientInfoTabs from "./PatientInfoTabs/PatientInfoTabs"

import styles from "./PatientDetailsPanel.module.scss"

interface PatientDetailsProps {
  conversation: AssignedConversation
  localConversationState?: LocalConversationState
}

const PatientDetailsPanel = ({ localConversationState }: PatientDetailsProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const isPanelOpen = useAppSelector(state => state.app.isDetailsPanelOpen)
  const activeLanguage = useAppSelector(state => state.app.language)
  const conversation = useAppSelector(selectConversation)
  const selectedJournal = localConversationState?.state.selectedJournal
  const [isDoctorJournalOpen, setDoctorJournalOpen] = useState<boolean>(false)

  const logAmplitude = useSelectedConversationAmplitudeLogger()

  const onClick = () => {
    dispatch(setDetailsPanelState(!isPanelOpen))
    logAmplitude("inbox.patient_details.pressed")
    if (isPanelOpen && conversation)
      dispatch(
        updateLocalConversationState({
          conversationId: conversation.id,
          state: { selectedJournal: undefined }
        })
      )
  }

  const hasDoctorJournal =
    featureFlags.has("webdoc") &&
    featureFlags.has("webdoc_journal_note_send") &&
    conversation?.journalNote?.data?.sections &&
    activeLanguage === "sv"

  return (
    <div
      className={classNames(
        styles.container,
        isDoctorJournalOpen ? styles.wide : styles.narrow,
        !isPanelOpen ? (isDoctorJournalOpen ? styles.closedWide : styles.closed) : "",
        { [styles.journalOpen]: selectedJournal }
      )}>
      <VerticalTab text={intl.formatMessage({ id: "conversation.details.tab" })} onClick={onClick} position="top" />
      <div className={styles.panel}>
        {hasDoctorJournal ? <PatientInfoTabs setDoctorJournalOpen={setDoctorJournalOpen} /> : <PatientInfo />}
        {featureFlags.has("journal") && <Journal selectedJournal={selectedJournal} />}
      </div>
    </div>
  )
}

export default PatientDetailsPanel
