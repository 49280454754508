import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "reducers/init"

export type PatientStatusState = Record<string, "online" | "offline">
const initialState: PatientStatusState = {}

const patientStatus = createSlice({
  name: "patientStatus",
  initialState,
  reducers: {
    setPatientStatus(state, action: PayloadAction<{ patientId: string; status: "online" | "offline" }>) {
      const { patientId, status } = action.payload
      state[patientId] = status
    }
  }
})

export const { setPatientStatus } = patientStatus.actions

export type PatientStatusSliceAction = ObjectFunctionReturnTypes<typeof patientStatus.actions>

export const patientStatusSelector = (state: RootState, patientId: string) => state.patientStatus[patientId]

export default patientStatus.reducer
