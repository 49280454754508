import { ICONS, StaffBodyTextS } from "@doktor-se/bones-ui"

import { DiagnosisCode } from "reducers/conversations/conversations.reducer"

import styles from "./CodeRow.module.scss"

interface CodeRowProps {
  favorite: boolean
  code: DiagnosisCode
  toggleFavorite: (code: DiagnosisCode, favorite: boolean) => void
  onCodeRemove: (code: DiagnosisCode) => void
}

const CodeRow = ({ code, favorite, toggleFavorite, onCodeRemove }: CodeRowProps) => {
  const Icon = favorite ? ICONS.Star : ICONS.StarOutline

  return (
    <div className={styles.row}>
      <div className={styles.rowContent} role="presentation">
        <StaffBodyTextS className={styles.codeName}>{code.label}</StaffBodyTextS>
        <Icon className={styles.starIcon} onClick={() => toggleFavorite(code, favorite)} />
      </div>
      <ICONS.Close className={styles.closeIcon} onClick={() => onCodeRemove(code)} />
    </div>
  )
}

export default CodeRow
