import { ProfileMessage } from "@doktor-se/bones-ui/dist/web-shared/types"

import { patientProfilesFetch } from "api"
import { Thunk } from "lib/hooks"
import { addMessage, changeProfile } from "reducers/conversations/conversations.reducer"

export const handleProfile =
  (message: ProfileMessage): Thunk =>
  async (dispatch, getState) => {
    if (message.conversationId) {
      const patientId = getState().conversations.conversations.entities[message.conversationId]?.patientId

      if (patientId) {
        const profiles = await dispatch(patientProfilesFetch(patientId))
        const newProfile = profiles?.find(profile => profile.id === message.data.newProfileId)
        dispatch(
          changeProfile({
            conversationId: message.conversationId,
            profile: newProfile
          })
        )
      }
    }

    dispatch(addMessage(message))
  }
