import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit"
import { createSelector } from "reselect"

import {
  AnyMessage,
  AssignedConversation,
  CallStateChangedMessage,
  ConversationStateChangedMessage,
  CustomerMetadata,
  JournalNote,
  MetaData,
  MetadataMessage,
  PatientComment,
  Profile,
  SnoozeMessage,
  User
} from "@doktor-se/bones-ui/dist/web-shared/types"

import { RootState } from "reducers/init"
import { profileRemoved, profileUpdated } from "reducers/users/users.reducer"

import { featureFlags } from "../../config"
import {
  addConversations,
  removeMessageInConversation,
  setComment,
  setJournalNote as setJounalNoteHelper,
  setJournalNoteWebdoc as setJournalWebdocHelper,
  setSnoozeConversation,
  transformConversation,
  unpostponeConversation
} from "./utils/conversations"
import {
  filterActiveAndPostponedConversations,
  filterActiveConversations,
  filterSnoozedConversations,
  filterUpcomingBookings
} from "./utils/filter"
import { setCall, transformMessage } from "./utils/messages"

export interface DiagnosisCode {
  value: string
  label: string
  isFavorite?: boolean
}

const conversationsAdapter = createEntityAdapter<AssignedConversation>()

export interface ConversationsState {
  conversations: EntityState<AssignedConversation, string>
  webdocDiagnosisCodes?: DiagnosisCode[]
}

const initialState: ConversationsState = {
  conversations: conversationsAdapter.getInitialState(),
  webdocDiagnosisCodes: undefined
}

const getAllConversationsByPatientID = (state: EntityState<AssignedConversation, string>, patientId: string) =>
  simpleConversationSelectors
    .selectAll(state)
    .filter(conversation => conversation.account && conversation.account.id === patientId)

const conversations = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    loadConversations(state, action: PayloadAction<AssignedConversation[]>) {
      conversationsAdapter.setAll(state.conversations, addConversations(action.payload))
    },
    loadConversation(state, action: PayloadAction<{ conversation: AssignedConversation; user: User }>) {
      // fix because omitted assignedStaffId is not triggering a state update when using update/upsert
      if (
        featureFlags.has("conversations_load_split") &&
        state.conversations.ids.includes(action.payload.conversation.id)
      ) {
        const update = {
          id: action.payload.conversation.id,
          changes: transformConversation(action.payload.conversation)
        }
        conversationsAdapter.updateOne(state.conversations, update)
      } else {
        conversationsAdapter.removeOne(state.conversations, action.payload.conversation.id)
        conversationsAdapter.addOne(state.conversations, transformConversation(action.payload.conversation))
      }
    },
    removeConversation(state, action: PayloadAction<string>) {
      conversationsAdapter.removeOne(state.conversations, action.payload)
    },
    addMessage(state, action: PayloadAction<AnyMessage>) {
      if (!action.payload.conversationId) return
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)
      const changes = conversation && transformMessage(conversation, action.payload)

      changes &&
        conversationsAdapter.updateOne(state.conversations, {
          id: action.payload.conversationId as string,
          changes
        })
    },
    removeMessage(state, action: PayloadAction<{ conversation: AssignedConversation; messageId: number }>) {
      const changes = removeMessageInConversation(action.payload.conversation, action.payload.messageId)

      changes &&
        conversationsAdapter.updateOne(state.conversations, {
          id: action.payload.conversation.id as string,
          changes
        })
    },
    messageRead(state, action: PayloadAction<{ conversationId: string; messageId: number }>) {
      conversationsAdapter.updateOne(state.conversations, {
        id: action.payload.conversationId,
        changes: { lastSeenMessageId: action.payload.messageId }
      })
    },
    patientComment(state, action: PayloadAction<PatientComment>) {
      const conversations = getAllConversationsByPatientID(state.conversations, action.payload.patientId)

      if (!conversations.length) return

      conversations.forEach(conversation =>
        conversationsAdapter.updateOne(state.conversations, {
          id: conversation.id,
          changes: setComment(conversation, action.payload.comment)
        })
      )
    },
    updateConversationState(state, action: PayloadAction<ConversationStateChangedMessage>) {
      action.payload.conversationId &&
        conversationsAdapter.updateOne(state.conversations, {
          id: action.payload.conversationId,
          changes: { state: action.payload.data.state }
        })
    },
    updateCallState(state, action: PayloadAction<CallStateChangedMessage>) {
      if (!action.payload.conversationId) return
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)
      const changes = conversation && setCall(conversation, action.payload)
      changes &&
        conversationsAdapter.updateOne(state.conversations, {
          id: action.payload.conversationId as string,
          changes
        })
    },
    snooze(state, action: PayloadAction<SnoozeMessage>) {
      if (!action.payload.conversationId) return

      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)
      const changes = conversation && setSnoozeConversation(conversation, action.payload)

      changes &&
        conversationsAdapter.updateOne(state.conversations, {
          id: action.payload.conversationId as string,
          changes
        })
    },
    removeSnooze(state, action: PayloadAction<string>) {
      conversationsAdapter.updateOne(state.conversations, {
        id: action.payload as string,
        changes: { snoozedUntil: undefined }
      })
    },
    setSnoozeTimeout(
      state,
      action: PayloadAction<{
        timeout: ReturnType<typeof setTimeout>
        data: { conversationId: string; snoozedUntil?: string }
      }>
    ) {
      conversationsAdapter.updateOne(state.conversations, {
        id: action.payload.data.conversationId,
        changes: { snoozedUntil: action.payload.data.snoozedUntil }
      })
    },
    removePostpone(state, action: PayloadAction<string>) {
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload)
      if (!conversation) return
      conversationsAdapter.updateOne(state.conversations, {
        id: action.payload,
        changes: unpostponeConversation(conversation)
      })
    },
    metadata(state, action: PayloadAction<MetadataMessage>) {
      if (!action.payload.conversationId) return
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)
      conversation &&
        conversationsAdapter.updateOne(state.conversations, {
          id: action.payload.conversationId,
          changes: { metadata: { ...conversation.metadata, ...action.payload } }
        })
    },
    setJournalNote(state, action: PayloadAction<JournalNote>) {
      if (!action.payload.conversationId) return
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)
      conversation &&
        conversationsAdapter.updateOne(state.conversations, {
          id: conversation.id,
          changes: setJounalNoteHelper(conversation, action.payload)
        })
    },
    setJournalNoteWebdoc(state, action: PayloadAction<JournalNote>) {
      if (!action.payload.conversationId) return
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)
      conversation &&
        conversationsAdapter.updateOne(state.conversations, {
          id: conversation.id,
          changes: setJournalWebdocHelper(conversation, action.payload)
        })
    },
    updateConversationMetadata(state, action: PayloadAction<{ conversationId: string; metadata: MetaData }>) {
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)
      conversation &&
        conversationsAdapter.updateOne(state.conversations, {
          id: action.payload.conversationId,
          changes: { metadata: { ...conversation.metadata, ...action.payload.metadata } }
        })
    },
    updateConversationCustomerMetadata(
      state,
      action: PayloadAction<{ conversationId: string; customerMetadata: CustomerMetadata }>
    ) {
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)
      conversation &&
        conversationsAdapter.updateOne(state.conversations, {
          id: action.payload.conversationId,
          changes: { customerMetadata: action.payload.customerMetadata }
        })
    },
    changeProfile(state, action: PayloadAction<{ conversationId: string; profile?: Profile }>) {
      const conversation = simpleConversationSelectors.selectById(state.conversations, action.payload.conversationId)

      if (!conversation) return

      const changes = {
        profile: action.payload.profile,
        history: conversation.history?.map(history => {
          if (history.id === conversation.id) {
            return { ...history, profileId: action.payload.profile?.id }
          }
          return history
        })
      }

      conversationsAdapter.updateOne(state.conversations, {
        id: action.payload.conversationId,
        changes
      })
    },
    setWebdocDiagnosisCodes(state, action: PayloadAction<DiagnosisCode[]>) {
      state.webdocDiagnosisCodes = action.payload
    }
  },

  extraReducers: builder =>
    builder
      .addCase(profileRemoved, (state, action) => {
        const conversations = getAllConversationsByPatientID(state.conversations, action.payload.patientId)

        if (!conversations.length) return

        conversations.forEach(conversation => {
          if (conversation.profile?.id === action.payload.profileId)
            conversationsAdapter.updateOne(state.conversations, {
              id: conversation.id,
              changes: { profile: undefined }
            })
        })
      })
      .addCase(profileUpdated, (state, action) => {
        const conversations = getAllConversationsByPatientID(state.conversations, action.payload.patientId)

        if (!conversations.length) return

        conversations.forEach(conversation => {
          if (conversation.profile?.id === action.payload.profile.id) {
            conversationsAdapter.updateOne(state.conversations, {
              id: conversation.id,
              changes: { profile: action.payload.profile }
            })
          }
        })
      })
})

export const {
  loadConversations,
  loadConversation,
  removeConversation,
  addMessage,
  removeMessage,
  messageRead,
  patientComment,
  updateConversationState,
  updateCallState,
  snooze,
  removeSnooze,
  setSnoozeTimeout,
  removePostpone,
  metadata,
  setJournalNote,
  setJournalNoteWebdoc,
  updateConversationMetadata,
  updateConversationCustomerMetadata,
  changeProfile,
  setWebdocDiagnosisCodes
} = conversations.actions

export type ConversationsSliceAction = ObjectFunctionReturnTypes<typeof conversations.actions>

export const simpleConversationSelectors = conversationsAdapter.getSelectors()

export const conversationsSelectors = conversationsAdapter.getSelectors<RootState>(
  state => state.conversations.conversations
)

export const selectConversation = createSelector(
  (state: RootState) => state.conversations.conversations,
  (state: RootState) => state.selected.conversationId,
  (conversations, conversationId) =>
    (!!conversationId && simpleConversationSelectors.selectAll(conversations).find(s => s.id === conversationId)) ||
    undefined
)

export const selectConversations = createSelector(
  (state: RootState) => state.conversations.conversations,
  (state: RootState) => state.auth.user,
  (state: RootState) => state.auth.defaultRole,
  (conversations, user) => {
    const open = simpleConversationSelectors.selectAll(conversations).filter(c => c.state === "opened")
    const active = filterActiveConversations(open, user)
    const assignedAndPostponed = filterActiveAndPostponedConversations(open, user)
    const snoozed = filterSnoozedConversations(open, user)
    const upcomingBookingsFromOpened = filterUpcomingBookings(open)
    let waiting: AssignedConversation[] = [...snoozed, ...assignedAndPostponed, ...upcomingBookingsFromOpened]

    const booked = simpleConversationSelectors.selectAll(conversations).filter(c => c.state === "booked")
    const upcomingBookingsFromBooked = filterUpcomingBookings(booked)
    waiting = [...waiting, ...upcomingBookingsFromBooked]

    return {
      active,
      snoozed,
      waiting
    }
  }
)

export default conversations.reducer
