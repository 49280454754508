import { GlobalSnackbarRegion } from "@doktor-se/bones-ui"
import {
  AssignedConversation,
  ButtonMessageRequest,
  ChatMessageRequest,
  CommentMessageRequest,
  ConversationHistory,
  EncryptionCustomerMetadata,
  JournalNote,
  JournalSection
} from "@doktor-se/bones-ui/dist/web-shared/types"
import { aspectRatio } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { handleErrors, handleErrorsV2 } from "api/error/handler"
import { apiFetch, externalFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { encryptFile, encryptMessage } from "lib/seald"
import {
  messageRead,
  removeMessage,
  setJournalNote,
  setJournalNoteWebdoc
} from "reducers/conversations/conversations.reducer"
import { updateLocalConversationState } from "reducers/selected"

import { featureFlags } from "../../config"

const URI_PREFIX: string = "/v2/conversations/"

export const lastMessageRead =
  (conversationId: string, messageId: number): Thunk =>
  dispatch => {
    dispatch(messageRead({ conversationId, messageId }))
    dispatch(
      apiFetch(`/conversations/${conversationId}/messages/${messageId}/seen`, {
        method: "POST"
      })
    ).catch((error: any) =>
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/messages/<messageId>/seen" }))
    )
  }

export const backupMessage =
  (conversationId: string, messageData: ChatMessageRequest): Thunk<Promise<boolean | undefined>> =>
  async dispatch => {
    try {
      await dispatch(
        apiFetch(`${URI_PREFIX}${conversationId}/messages`, {
          method: "POST",
          body: JSON.stringify(messageData)
        })
      )

      dispatch(updateLocalConversationState({ conversationId, state: { messageDraft: "" } }))
      return true
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/messages" }))
    }
  }

export const sendMessage =
  (
    conversationId: string,
    message: string,
    staffName?: string,
    patientId?: string,
    sealdEncryptionFeatureEnabled?: boolean,
    encryptionCustomerMetadata?: EncryptionCustomerMetadata
  ): Thunk<Promise<boolean>> =>
  async dispatch => {
    if (conversationId) {
      try {
        let text = message
        if (sealdEncryptionFeatureEnabled) {
          if (!encryptionCustomerMetadata?.currentSessionId) throw new Error("encryption.without.session")
          text = (await encryptMessage(encryptionCustomerMetadata?.currentSessionId, message))!
        }
        const data: ChatMessageRequest = {
          type: "chat",
          text: text!,
          from: staffName,
          patient_id: patientId
        }
        await dispatch(backupMessage(conversationId, data))
        return true
      } catch (error: any) {
        if (error === "encryption.without.session" || error.details.code === "error.encryption.encrypt.failed") {
          dispatch(handleErrorsV2({ error, customMessageLokaliseKey: "error.encryption.encrypt.failed" }))
        }
      }
    }
    return false
  }

export const sendComment =
  (conversationId: string, comment: string): Thunk =>
  dispatch => {
    const data: CommentMessageRequest = {
      type: "comment",
      text: comment
    }

    dispatch(
      apiFetch(`${URI_PREFIX}${conversationId}/messages`, {
        method: "POST",
        body: JSON.stringify(data)
      })
    )
      .then(() => dispatch(updateLocalConversationState({ conversationId, state: { commentDraft: "" } })))
      .catch((error: any) =>
        dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/messages" }))
      )
  }

export const openJournalNotes =
  (conversationId: string, selectedJournal: ConversationHistory): Thunk =>
  dispatch => {
    dispatch(updateLocalConversationState({ conversationId, state: { selectedJournal } }))
  }

export const saveJournalNote =
  (conversationId: string, sections: JournalSection[], approved?: boolean, codes?: string[]): Thunk =>
  (dispatch, getState) => {
    const data = {
      type: "journal_note",
      approved: approved ? approved : false,
      sections: sections.filter(item => item.body !== "").map(item => ({ title: item.title, body: item.body })),
      codes
    }
    dispatch(
      apiFetch(`${URI_PREFIX}${conversationId}/messages`, {
        method: "POST",
        body: JSON.stringify(data)
      })
    )
      .then((response: any) => ({
        ...response,
        data: JSON.parse(response.data)
      }))
      .then((response: JournalNote) => {
        if (featureFlags.has("webdoc")) {
          dispatch(setJournalNoteWebdoc(response))
          GlobalSnackbarRegion.queue.add({ text: "Saved in conversation", variant: "success" }, { timeout: 4000 })
        }
        if (featureFlags.has("journal")) {
          dispatch(setJournalNote(response))
          const localConversationState = (getState().selected.localConversationStates || []).find(
            s => s.conversationId === conversationId
          )
          dispatch(
            updateLocalConversationState({
              conversationId,
              state: {
                journalDraft: response,
                selectedJournal: { ...localConversationState!.state.selectedJournal!, journalNote: response }
              }
            })
          )
        }
      })
      .catch((error: any) =>
        dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/messages" }))
      )
  }
const saveImg =
  (key: string, conversationId: string, file: File, staffName?: string, patientId?: string): Thunk =>
  dispatch =>
    aspectRatio(file, (result: number) => {
      const data = {
        upload_key: key,
        type: "image",
        aspect_ratio: result,
        from: staffName,
        patient_id: patientId
      }
      return dispatch(
        apiFetch(`${URI_PREFIX}${conversationId}/messages`, {
          method: "POST",
          loader: "FILE UPLOAD",
          body: JSON.stringify(data)
        })
      ).catch((error: any) =>
        dispatch(handleErrors({ error, customContext: `${URI_PREFIX}<conversationId>/messages` }))
      )
    })

const saveAttachment =
  (key: string, conversationId: string, file: File): Thunk =>
  dispatch => {
    try {
      const data = {
        upload_key: key,
        type: "attachment",
        mime_type: file.type,
        file_name: file.name
      }

      return dispatch(
        apiFetch(`${URI_PREFIX}${conversationId}/messages`, {
          method: "POST",
          loader: "FILE UPLOAD",
          body: JSON.stringify(data)
        })
      )
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: `${URI_PREFIX}<conversationId>/messages` }))
    }
  }

const uploadFileToUrl =
  (
    file: File,
    upload: { url: string; key: string },
    conversationId: string,
    staffName?: string,
    patientId?: string,
    type?: "attachment" | "image",
    sealdEncryptionFeatureEnabled?: boolean,
    encryptionCustomerMetadata?: EncryptionCustomerMetadata
  ): Thunk =>
  async dispatch => {
    try {
      let fileData = file
      if (sealdEncryptionFeatureEnabled) {
        if (!encryptionCustomerMetadata?.currentSessionId) throw new Error("encryption.without.session")
        fileData = new File(
          [(await encryptFile(encryptionCustomerMetadata?.currentSessionId, file)) as Blob],
          file.name,
          { type: file.type }
        )
      }
      dispatch(
        externalFetch(upload.url, {
          method: "PUT",
          loader: "FILE UPLOAD",
          headers: {
            "Content-Type": file.type
          },
          body: fileData
        })
      )
        // Save file to conversation
        .then(() => {
          if (type === "image") {
            dispatch(saveImg(upload.key, conversationId, file, staffName, patientId))
          } else if (type === "attachment") {
            dispatch(saveAttachment(upload.key, conversationId, file))
          }
        })
        .catch((error: any) => dispatch(handleErrors({ error })))
    } catch (error: any) {
      if (error === "encryption.without.session" || error.details.code === "error.encryption.encrypt.failed") {
        dispatch(handleErrorsV2({ error, customMessageLokaliseKey: "error.encryption.encrypt.failed" }))
      }
    }
  }

export const uploadFile =
  (
    file: File,
    conversationId: string,
    staffName?: string,
    patientId?: string,
    sealdEncryptionFeatureEnabled?: boolean,
    encryptionCustomerMetadata?: EncryptionCustomerMetadata
  ): Thunk =>
  async dispatch => {
    if (file) {
      let type: "image" | "attachment" | undefined
      try {
        switch (file.type) {
          case "application/pdf":
            type = "attachment"
            break
          case "image/jpeg":
            type = "image"
            break
          default:
            break
        }

        // Gets upload url
        const response: { url: string; key: string } = await dispatch(
          apiFetch(`${URI_PREFIX}${conversationId}/upload`, {
            method: "POST",
            loader: "FILE UPLOAD",
            body: JSON.stringify({
              mime_type: file.type,
              type
            })
          })
        )

        dispatch(
          uploadFileToUrl(
            file,
            response,
            conversationId,
            staffName,
            patientId,
            type,
            sealdEncryptionFeatureEnabled,
            encryptionCustomerMetadata
          )
        )
      } catch (error: any) {
        dispatch(handleErrors({ error, customContext: `${URI_PREFIX}<conversationId>/upload` }))
      }
    }
  }

export const sendActionButton =
  (conversationId: string, title: string, uri: string, event?: string): Thunk =>
  async dispatch => {
    const data: ButtonMessageRequest = {
      type: "button",
      title,
      event,
      action: { type: "webview", uri }
    }

    try {
      await dispatch(
        apiFetch(`${URI_PREFIX}${conversationId}/messages`, {
          method: "POST",
          body: JSON.stringify(data)
        })
      )
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/messages" }))
    }
  }

export const fetchSignedUrl =
  (key: string, conversationId: string): Thunk<Promise<string | undefined>> =>
  async dispatch => {
    try {
      const response: { url: string } = await dispatch(
        apiFetch(`/conversations/${conversationId}/attachment/${key}`, {
          method: "GET"
        })
      )

      return response.url
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/attachment/<key>" }))
    }
  }

export const deleteMessage =
  (messageId: number, conversation: AssignedConversation): Thunk =>
  async dispatch => {
    try {
      await dispatch(
        apiFetch(`/conversations/${conversation.id}/undo`, {
          method: "POST",
          body: JSON.stringify({ message_id: messageId })
        })
      )
      // Remove message from global state to visually remove it instantly
      dispatch(removeMessage({ conversation, messageId }))
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/undo" }))
    }
  }

interface BulkMessageParams {
  text: string
  queues: string[]
  timeInQueue: number
  patientsToSkip: number
  postponedFrom?: Date
  postponedTo?: Date
}

export const sendBulkMessage =
  (params: BulkMessageParams): Thunk<Promise<boolean>> =>
  async dispatch => {
    const body = {
      text: params.text,
      queues: params.queues,
      time_in_queue: params.timeInQueue,
      skip_patients: params.patientsToSkip,
      ...(params.postponedFrom && { postponed_from: params.postponedFrom }),
      ...(params.postponedTo && { postponed_to: params.postponedTo })
    }
    try {
      await dispatch(
        apiFetch(`/messages/bulk_message`, {
          method: "POST",
          body: JSON.stringify(body)
        })
      )
      return true
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/messages/bulk_message" }))
      return false
    }
  }

export interface HistoryMessages {
  messages: HistoryMessage[]
}

export interface HistoryMessage {
  id: string
  text: string
  timeInQueue: number
  queues: string[]
  created: string
  postponedFrom: string
  postponedTo: string
  skipPatients: number
  clinicId: string
}

export const getBulkMessageHistory = (): Thunk<Promise<HistoryMessages | undefined>> => async dispatch => {
  try {
    const response: HistoryMessages = await dispatch(
      apiFetch(`/messages/bulk_message`, {
        method: "GET"
      })
    )
    return response
  } catch (error: any) {
    dispatch(handleErrors({ error, customContext: "/messages/bulk_message" }))
  }
}
