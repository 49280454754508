import { Clinic } from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors } from "api/error/handler"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { loadClinics, loadPatientClinics } from "reducers/clinics"

interface ClinicApi {
  address: string
  clinicId: string
  name: string
  supportedQueueIds?: string[]
}

interface ClinicsApiResponse {
  count: number
  results: ClinicApi[]
}

export const clinicsFetch = (): Thunk => async dispatch => {
  try {
    const response: ClinicsApiResponse = await dispatch(apiFetch("/v2/clinic"))
    const mappedResponse = response.results.map(
      c =>
        ({
          id: c.clinicId,
          name: c.name,
          subtitle: c.address,
          supported_queue_ids: c.supportedQueueIds
        }) as Clinic
    )
    dispatch(loadClinics(mappedResponse))
  } catch (error: any) {
    dispatch(handleErrors({ error }))
  }
}

export const fetchPatientsClinics =
  (patientId: string): Thunk =>
  async dispatch => {
    dispatch(loadPatientClinics([]))
    const response = await dispatch(apiFetch(`/patients/${patientId}/clinics`))
    dispatch(loadPatientClinics(response))
  }
