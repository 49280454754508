import { useMemo } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"
import dayjs from "dayjs"

import { ICONS, StaffBodyTextXS, StaffBodyTextXXS, StaffHeading4 } from "@doktor-se/bones-ui"
import { useTimeToNow } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { AssignedConversation, CallMessageFiltered, Category } from "@doktor-se/bones-ui/dist/web-shared/types"
import { formatPnr } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { featureFlags } from "config"
import { useAppSelector, useStaffById } from "lib/hooks"
import { selectBookingById } from "reducers/booking"

import CategoryBadge from "components/CategoryBadge/CategoryBadge"
import CountryFlag from "components/CountryFlag/CountryFlag"
import PatientAvatar from "components/PatientAvatar/PatientAvatar"
import { ButtonPhone } from "icons"

import { ConversationBookingDetails } from "./ConversationBookingDetails"
import PatientOnlineStatus from "./PatientOnlineStatus/PatientOnlineStatus"

import styles from "./ConversationBox.module.scss"

export interface ConversationBoxProps {
  isSelected: boolean
  conversation: AssignedConversation
  setConversation: (c: AssignedConversation) => void
  categories: Record<number, Category>
  notify?: boolean
}

const ConversationBox = ({
  conversation,
  isSelected,
  setConversation,
  categories,
  notify = false
}: ConversationBoxProps): JSX.Element => {
  const {
    profile,
    snoozedUntil,
    postponedUntil,
    lastMessage,
    assignedStaffId,
    account,
    callState,
    id,
    metadata,
    categoryId
  } = conversation

  const intl = useIntl()
  const booking = useAppSelector(selectBookingById(metadata?.bookingId))
  const activeTab = useAppSelector(state => state.app.activeInboxTab)

  const sealdEncryptionFeatureEnabled = useAppSelector(state => state.encryption.encryptionEnabled)

  const lastAssignedStaffId = conversation.assignedStaffId

  const lastAssignedStaff = useStaffById(lastAssignedStaffId)

  const isSnoozed = useMemo(() => !!snoozedUntil && dayjs(snoozedUntil).diff(dayjs()) > 0, [snoozedUntil])

  const isPostponed = useMemo(() => !!postponedUntil && dayjs(postponedUntil).diff(dayjs()) > 0, [postponedUntil])

  const snoozeTime = useTimeToNow(isSnoozed ? snoozedUntil : undefined, 10000)
  const postponedTime = useTimeToNow(isPostponed ? postponedUntil : undefined, 10000)
  const isPremiumHealth = useMemo(() => {
    return categoryId && categories[categoryId] && categories[categoryId]?.metadata.conversationMetaInit?.premiumHealth
  }, [categories, categoryId])
  const isRevisit = useMemo(() => {
    return categoryId && categories[categoryId] && categories[categoryId]?.metadata.conversationMetaInit?.revisit
  }, [categories, categoryId])
  const categoryBadgeType = useMemo(() => {
    if (isPremiumHealth) return "premiumHealth"
    if (isRevisit) return "revisit"
    return "general"
  }, [isPremiumHealth, isRevisit])

  const message = useMemo(() => {
    // if seald encryption is enabled we dont show preview of encrypted messages
    if (sealdEncryptionFeatureEnabled) return
    switch (lastMessage?.type) {
      case "chat":
        return `
            ${assignedStaffId === lastMessage.createdById ? intl.formatMessage({ id: "conversations.label.you" }) : ""}
            ${
              account && account.id === lastMessage.createdById
                ? `${account.displayName.substr(0, account.displayName.indexOf(" "))}: `
                : ""
            }
            ${
              lastMessage.data.text && lastMessage.data.text.length > 60
                ? `${lastMessage.data.text?.substr(0, 59)}...`
                : lastMessage.data.text
            }
          `

      case "image":
        return `
            ${
              assignedStaffId === lastMessage.createdById
                ? intl.formatMessage({ id: "conversations.label.photo.you" })
                : ""
            }
            ${
              account && account.id === lastMessage.createdById
                ? intl.formatMessage(
                    {
                      id: "conversations.label.photo"
                    },
                    {
                      user: account.displayName.substr(0, account.displayName.indexOf(" "))
                    }
                  )
                : ""
            }
          `

      case "attachment":
        return `
            ${
              assignedStaffId === lastMessage.createdById
                ? intl.formatMessage({ id: "conversations.label.attachment.you" })
                : ""
            }
            ${
              account && account.id === lastMessage.createdById
                ? intl.formatMessage(
                    {
                      id: "conversations.label.attachment"
                    },
                    {
                      user: account.displayName.substr(0, account.displayName.indexOf(" "))
                    }
                  )
                : ""
            }
          `

      case "call":
        return intl.formatMessage({ id: (lastMessage as CallMessageFiltered).data.text })

      case "sip":
        return intl.formatMessage({ id: "conversations.label.call.to" }, { number: lastMessage.data.calledNumber })

      case "button":
        return intl.formatMessage({ id: "action.button.sent" }, { title: lastMessage.data.title })

      default:
        return null
    }
  }, [account, assignedStaffId, intl, lastMessage, sealdEncryptionFeatureEnabled])

  return (
    <button
      data-testid={`inboxConversation-${id}`}
      className={classNames(styles.conversation, styles[activeTab], {
        [styles.active]: isSelected,
        [styles.snoozed]: isSnoozed,
        [styles.postponed]: isPostponed,
        [styles.notify]: notify,
        [styles.incomingCall]: callState === "incoming"
      })}
      id={id}
      key={id}
      onClick={() => {
        setConversation(conversation)
      }}>
      <div className={styles.header}>
        {notify && callState === "incoming" && <ButtonPhone fill="#FFF" className={styles.phone} />}
        <div className={styles.bubbleContainer}>
          {featureFlags.has("patient_avatar") && (
            <PatientAvatar
              size="small"
              letter={account?.displayName?.charAt(0)}
              imgName={featureFlags.has("profile_icon") ? profile?.data.icon : undefined}
            />
          )}
          <div className={styles.symptomBubbleWrapper}>
            <CategoryBadge
              size="small"
              category={categoryId ? categories[categoryId] : undefined}
              type={categoryBadgeType}
            />
            <PatientOnlineStatus patientId={conversation.patientId} />
          </div>
          {featureFlags.has("region") && metadata?.region && (
            <CountryFlag className={styles.regionFlag} countryOrLanguageCode={metadata.region} />
          )}
          {profile?.type === "child" && <ICONS.Child className={styles.childIcon} />}
        </div>

        <div>
          <StaffHeading4 as="p">
            {profile?.type === "child"
              ? profile.name
              : account?.displayName || intl.formatMessage({ id: "label.unknown" })}
          </StaffHeading4>
          {!featureFlags.has("hide_pnr_in_header") && (
            <StaffBodyTextXS as="p">
              {profile?.type === "child"
                ? formatPnr(profile.data.securityNumber, account.region)
                : formatPnr(account?.pnr, account.region)}
            </StaffBodyTextXS>
          )}
        </div>
      </div>

      {!!message && (
        <>
          <hr className={styles.divider} />
          <StaffBodyTextXS margin={{ top: "var(--size-100)" }} className={styles.message}>
            {message}
          </StaffBodyTextXS>
        </>
      )}

      {isSnoozed && (
        <div className={styles.timeInfo}>
          <StaffBodyTextXXS>{intl.formatMessage({ id: "label.snoozed" })}</StaffBodyTextXXS>
          <StaffBodyTextXXS margin={{ left: "10px" }} className={styles.waitingTime}>
            {snoozeTime.date
              ? `${intl.formatDate(snoozeTime.date, {
                  day: "numeric",
                  month: "short"
                })} ${intl.formatTime(snoozeTime.date, {
                  hour: "numeric",
                  minute: "numeric"
                })}`
              : snoozeTime.timeLeft}
          </StaffBodyTextXXS>
        </div>
      )}

      {isPostponed && !booking && (
        <>
          <div className={styles.timeInfo}>
            <StaffBodyTextXXS>{intl.formatMessage({ id: "postpone.expires" })}</StaffBodyTextXXS>
            <StaffBodyTextXXS margin={{ left: "10px" }} className={styles.waitingTime}>
              {postponedTime.date
                ? `${intl.formatDate(postponedTime.date, {
                    day: "numeric",
                    month: "short"
                  })} ${intl.formatTime(postponedTime.date, {
                    hour: "numeric",
                    minute: "numeric"
                  })}`
                : postponedTime.timeLeft}
            </StaffBodyTextXXS>
          </div>

          <StaffBodyTextXXS margin={{ top: "7px" }} className={styles.reassignedInfo}>
            {intl.formatMessage({ id: "postponed.from" }, { staff: lastAssignedStaff?.displayName })}
          </StaffBodyTextXXS>
        </>
      )}

      {booking && <ConversationBookingDetails booking={booking} onlyTime={activeTab !== "waiting"} />}
    </button>
  )
}

export default ConversationBox
