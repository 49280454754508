import { useIntl } from "react-intl"
import { useQuery } from "react-query"

import { Button, Dropdown, StaffBodyTextS, StaffBodyTextXS, StaffHeading3 } from "@doktor-se/bones-ui"
import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"
import { LanguageCode } from "@doktor-se/bones-ui/dist/web-shared/types"

import { fetchCloseOptions } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"

import styles from "./CloseOptionsContent.module.scss"

interface CloseOption {
  id: string
  title: string
  translations: Partial<{ [key in LanguageCode]: string }>
}

export interface CloseOptionsContentProps {
  categoryId: number | undefined
  closeOptionId: string
  setCloseOptionId: (closeOptionId: string) => void
  onConfirm?: () => void
  showHeading?: boolean
}

const CloseOptionsContent = ({
  closeOptionId,
  setCloseOptionId,
  onConfirm,
  categoryId,
  showHeading = true
}: CloseOptionsContentProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const languageCode = useAppSelector(state => state.app.language)

  const { isLoading, data } = useQuery("closeOptions", () => dispatch(fetchCloseOptions(categoryId)))
  const closeOptions: CloseOption[] | undefined = data?.options

  return (
    <>
      {showHeading && (
        <>
          <StaffHeading3>
            {intl.formatMessage({
              id: "control.close"
            })}
          </StaffHeading3>
          <StaffBodyTextS margin={{ bottom: "var(--size-300)" }}>
            {intl.formatMessage({ id: "control.close.options.title" })}
          </StaffBodyTextS>
        </>
      )}
      {isLoading ? (
        <div className={styles.loader}>
          <Loader type="small" />
        </div>
      ) : (
        <>
          <label htmlFor="closing-options" className={styles.label}>
            <StaffBodyTextXS as="span">{intl.formatMessage({ id: "control.close.options.label" })}</StaffBodyTextXS>
          </label>
          <div className={styles.selectContainer}>
            <Dropdown
              id="closing-options"
              variant="onSurface"
              label={intl.formatMessage({ id: "dropdown.select.close.option.aria.label" })}
              selectedKey={closeOptionId}
              onSelectionChange={key => setCloseOptionId(key as string)}
              placeholder={intl.formatMessage({ id: "control.close.options.option" })}
              items={
                closeOptions?.map(option => ({
                  id: option.id,
                  name: option.translations[languageCode] || option.title
                })) || []
              }
            />
          </div>
          {onConfirm && (
            <Button variant="primary" color="primary" isDisabled={!closeOptionId} onPress={onConfirm} fullWidth>
              {intl.formatMessage({ id: "label.close" })}
            </Button>
          )}
        </>
      )}
    </>
  )
}

export default CloseOptionsContent
