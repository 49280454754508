import OT from "@opentok/client"
import dayjs from "dayjs"

import { CustomError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import {
  AssignedConversation,
  CallAnswerReason,
  CallEndReason,
  CallStartReason,
  CallStateChangedMessage
} from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors } from "api/error/handler"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { parseMessage } from "lib/parse"
import { simpleConversationSelectors } from "reducers/conversations/conversations.reducer"
import {
  clearTokboxConnect,
  openCall,
  resetCallsState,
  setProxyUrl,
  setSessionReconnecting,
  setTokboxConnect,
  setTokboxConnectTimeout,
  toggleTokboxExpanded,
  updateCall,
  updateTokboxHasVideo,
  updateTokboxInputDevices,
  updateTokboxPubOptions,
  updateTokboxTimer
} from "reducers/tokbox"

let session: OT.Session | undefined = undefined
let publisher: OT.Publisher | undefined = undefined

export const callEndError =
  (error: any): Thunk =>
  dispatch => {
    dispatch(
      handleErrors({
        error,
        customMessage: "calls.error.end"
      })
    )
  }

export const tokboxNetworkTest =
  (): Thunk<Promise<{ token: string; sessionId: string; apiKey: string } | undefined>> => async dispatch => {
    try {
      const response: { key: string; token: string; sessionId: string } = await dispatch(
        apiFetch("/opentok/precall", {
          method: "POST"
        })
      )
      return {
        token: response.token,
        sessionId: response.sessionId,
        apiKey: response.key
      }
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }

export const fetchCallToken =
  (conversation: AssignedConversation, sessionId?: string): Thunk<Promise<any>> =>
  dispatch => {
    const data = {
      conversation_id: conversation.id,
      session_id: sessionId
    }
    // eslint-disable-next-line no-console
    console.log("Opentok session id: ", sessionId)
    // eslint-disable-next-line no-console
    console.log("Conversation id: ", conversation.id)

    return dispatch(
      apiFetch("/opentok/token", {
        method: "POST",
        body: JSON.stringify(data)
      })
    )
      .then((response: { key: string; token: string; proxyUrl?: string }) => {
        dispatch(
          setTokboxConnect({
            token: response.token,
            sessionId,
            apiKey: response.key
          })
        )
      })
      .catch((error: any) => dispatch(handleErrors({ error })))
  }

export const fetchProxyUrl = (): Thunk<Promise<string | undefined>> => async dispatch => {
  try {
    const response: { proxyUrl?: string } = await dispatch(apiFetch("/opentok/proxy_url"))
    dispatch(setProxyUrl(response.proxyUrl))
    return response.proxyUrl
  } catch (error: any) {
    dispatch(handleErrors({ error }))
  }
}

const apiStartCall =
  (conversation: AssignedConversation, reason: CallStartReason): Thunk<Promise<CallStateChangedMessage>> =>
  async dispatch => {
    try {
      const message = await dispatch(
        apiFetch(`/v2/conversations/${conversation.id}/call/start`, {
          method: "POST",
          body: JSON.stringify({
            reason,
            patient_id: conversation.account && conversation.account.id
          })
        })
      )
      return parseMessage(message) as CallStateChangedMessage
    } catch (error: any) {
      dispatch(
        handleErrors({
          error,
          customMessage: "calls.error.start",
          customContext: "/v2/conversations/<conversationId>/call/start"
        })
      )
      throw error
    }
  }

export const apiEndCall =
  (conversationId: string, reason: CallEndReason, accountId?: string): Thunk<Promise<any>> =>
  dispatch =>
    dispatch(
      apiFetch(`/conversations/${conversationId}/call/end`, {
        method: "POST",
        body: JSON.stringify({
          reason,
          patient_id: accountId
        })
      })
    )

export const apiAnswerCall =
  (conversation: AssignedConversation, reason: CallAnswerReason): Thunk<Promise<CallStateChangedMessage>> =>
  async dispatch => {
    try {
      const message = await dispatch(
        apiFetch(`/conversations/${conversation.id}/call/answer`, {
          method: "POST",
          body: JSON.stringify({
            reason,
            patient_id: conversation.account && conversation.account.id
          })
        })
      )
      return parseMessage(message) as CallStateChangedMessage
    } catch (error: any) {
      dispatch(
        handleErrors({
          error,
          customMessage: "calls.error.answer",
          customContext: "/conversations/<conversationId>/call/answer"
        })
      )
      throw error
    }
  }

export const startCall =
  (conversation: AssignedConversation, reason: CallStartReason): Thunk =>
  async dispatch => {
    try {
      dispatch(clearTokboxConnect())
      dispatch(openCall(conversation.id))
      const response: CallStateChangedMessage = await dispatch(apiStartCall(conversation, reason))

      if (response.data.sessionId && response.data.sessionId !== "error") {
        dispatch(fetchCallToken(conversation, response.data.sessionId))
      } else {
        throw new CustomError("The call does not have a tokbox session id", conversation.id)
      }
    } catch (error: any) {
      let customMessage
      if (error.message === "The call does not have a tokbox session id") customMessage = "sessionid.empty"
      dispatch(handleErrors({ error, customMessage }))
    }
  }

export const endCall =
  (conversation: AssignedConversation, reason: CallEndReason): Thunk<Promise<any>> =>
  dispatch =>
    dispatch(apiEndCall(conversation.id, reason, conversation.account?.id)).catch((error: any) => {
      // Do not display any conflict errors since they are expected
      if (error.status !== 409) {
        dispatch(callEndError(error))
      }
    })

export const forceEndCall = (): Thunk<Promise<any>> => (dispatch, getState) => {
  const { tokbox, conversations } = getState()
  const conversation = simpleConversationSelectors
    .selectAll(conversations.conversations)
    .find(c => c.id === tokbox.conversationId)
  if (conversation && ["active", "outgoing"].includes(tokbox.callState)) {
    return dispatch(endCall(conversation, tokbox.callState === "active" ? "hangup" : "no_answer")).then(() =>
      dispatch(updateCall({ callState: "inactive" }))
    )
  }

  return Promise.resolve()
}

export const startCallWithActive =
  (
    startConv: AssignedConversation,
    startReason: CallStartReason,
    endConv: AssignedConversation,
    endReason?: CallEndReason
  ): Thunk =>
  dispatch => {
    if (endReason) {
      dispatch(apiEndCall(endConv.id, endReason, endConv.account?.id))
        .then(() => dispatch(startCall(startConv, startReason)))
        .catch((error: any) => dispatch(callEndError(error)))
    }
  }

export const answerCall =
  (conversation: AssignedConversation, reason: CallAnswerReason): Thunk =>
  async dispatch => {
    try {
      const incomingCalls = conversation.messages?.filter(
        m => m.type === "call" && (m as CallStateChangedMessage).data.callState === "incoming"
      ) as CallStateChangedMessage[]
      const sessionId = incomingCalls[incomingCalls.length - 1].data.sessionId

      if (sessionId && sessionId !== "error") {
        dispatch(clearTokboxConnect())
        dispatch(openCall(conversation.id))
        await dispatch(apiAnswerCall(conversation, reason))
        dispatch(fetchCallToken(conversation, sessionId))
      } else {
        throw new CustomError("The call does not have a tokbox session id", conversation.id)
      }
    } catch (error: any) {
      let customMessage
      if (error.message === "The call does not have a tokbox session id") customMessage = "sessionid.empty"
      dispatch(handleErrors({ error, customMessage }))
    }
  }

const handleError =
  (conversation: AssignedConversation, error: any): Thunk =>
  (dispatch, getState) => {
    if (error) {
      if (getState().tokbox.callState !== "inactive") dispatch(endCall(conversation, "error"))
      if (error.name !== "OT_STREAM_DESTROYED") dispatch(handleErrors({ error }))
    }
  }

export const getDevices = (): Thunk => dispatch => {
  OT.getDevices((_, devices) => {
    if (devices) {
      dispatch(
        updateTokboxInputDevices({
          audio: devices.filter(element => element.kind === "audioInput"),
          video: devices.filter(element => element.kind === "videoInput")
        })
      )
    }
  })
}

export const stopStreamTracks = (stream?: MediaStream): void => {
  if (stream) {
    stream.getTracks().forEach((track: MediaStreamTrack) => {
      track.stop()
    })
  }
}

export const checkPermissions =
  (continueCall?: (stream?: MediaStream) => void, stopTracks: boolean = true): Thunk =>
  async (dispatch, getState) => {
    try {
      const { audioSource, videoSource } = getState().tokbox.pubOptions
      const stream = await OT.getUserMedia({ audioSource, videoSource })
      if (stopTracks) stopStreamTracks(stream)
      continueCall?.(stream)
    } catch (error: any) {
      let customMessage: string | undefined
      switch (error.name) {
        case "OT_CONSTRAINTS_NOT_SATISFIED":
          if (error.constraintName === "deviceId") customMessage = "error.device.missing"
          break
        case "OT_HARDWARE_UNAVAILABLE":
          customMessage = "error.device.unavailable"
          break
        case "OT_NO_DEVICES_FOUND":
          customMessage = "error.device.not_found"
          break
        default:
          break
      }
      dispatch(handleErrors({ error, customMessage }))
    }
  }

export const toggleLocalExpanded = (): Thunk => dispatch => {
  dispatch(toggleTokboxExpanded())
}

export const toggleLocalAudio =
  (): Thunk =>
  (dispatch, getState): void => {
    const { pubOptions } = getState().tokbox
    if (publisher) publisher.publishAudio(!pubOptions.publishAudio)
    dispatch(updateTokboxPubOptions({ publishAudio: !pubOptions.publishAudio }))
  }

export const toggleLocalVideo =
  (): Thunk =>
  (dispatch, getState): void => {
    const { pubOptions } = getState().tokbox
    if (publisher) publisher.publishVideo(!pubOptions.publishVideo)
    dispatch(updateTokboxPubOptions({ publishVideo: !pubOptions.publishVideo }))
  }

const resetState = (): Thunk => (dispatch, getState) => {
  const { timer, connectTimeout } = getState().tokbox
  if (timer.callTimerInterval) clearInterval(timer.callTimerInterval)
  if (connectTimeout) clearTimeout(connectTimeout)
  dispatch(resetCallsState())
}

export const startCallTimer =
  (): Thunk =>
  (dispatch, getState): void => {
    dispatch(updateTokboxTimer({ startTimer: dayjs() }))
    const callTimerInterval = setInterval(() => {
      const { startTimer } = getState().tokbox.timer
      const diff = dayjs().diff(startTimer || dayjs(), "milliseconds")
      dispatch(
        updateTokboxTimer({
          text: dayjs(diff).format("mm:ss")
        })
      )
    }, 1000)
    dispatch(updateTokboxTimer({ callTimerInterval }))
  }

/* 
List of all event one can listen to for the publisher, subscriber and session. Keeping this for easy access while debugging.
Just loop through and add listeners for the events you want eg.

publisherEvents.forEach(event => {
  publisher?.on(event, (evt: any) => {
    console.log("publisher event", evt)
  })
})

const publisherEvents = [
  "accessAllowed",
  "accessDenied",
  "accessDialogClosed",
  "accessDialogOpened",
  "audioLevelUpdated",
  "destroyed",
  "mediaStopped",
  "streamCreated",
  "streamDestroyed",
  "videoElementCreated"
]

const subscriberEvents = [
  "audioLevelUpdated",
  "connected",
  "destroyed",
  "videoDisabled",
  "videoDisableWarning",
  "videoDisableWarningLifted",
  "videoElementCreated",
  "videoEnabled"
]

const sessionEvents = [
  "archiveStarted",
  "archiveStopped",
  "connectionCreated",
  "connectionDestroyed",
  "sessionConnected",
  "sessionDisconnected",
  "sessionReconnected",
  "sessionReconnecting",
  "signal",
  "streamCreated",
  "streamDestroyed",
  "streamPropertyChanged"
] */

const retryHandler = (retry: number, errorHandler: () => void, retryFunc: () => void, error?: OT.OTError) => {
  if (error) {
    if (retry > 3) {
      errorHandler()
    } else {
      setTimeout(() => {
        retryFunc()
      }, 500)
    }
  }
}

const initPublisher =
  (conversation: AssignedConversation, retry: number = 0, videoSource?: MediaStreamTrack): Thunk<OT.Publisher> =>
  (dispatch, getState) => {
    const { pubOptions } = getState().tokbox

    return OT.initPublisher(
      "publisher",
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        audioSource: pubOptions.audioSource,
        videoSource: videoSource || pubOptions.videoSource,
        publishAudio: pubOptions.publishAudio,
        publishVideo: pubOptions.publishVideo,
        showControls: false
      },
      (error: any) => {
        retryHandler(
          retry,
          () => dispatch(handleError(conversation, error)),
          () => dispatch(initPublisher(conversation, retry + 1, videoSource)),
          error
        )
      }
    )
  }

const initSubscriber =
  (stream: OT.Stream, conversation: AssignedConversation, retry: number = 0): Thunk<OT.Subscriber | undefined> =>
  dispatch => {
    return session?.subscribe(
      stream,
      "subscriber",
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        showControls: false
      },
      (error: any) => {
        retryHandler(
          retry,
          () => dispatch(handleError(conversation, error)),
          () => dispatch(initSubscriber(stream, conversation, retry + 1)),
          error
        )
      }
    )
  }

export const initializeTokbox =
  (
    sessionId?: string,
    token?: string,
    conversation?: AssignedConversation,
    apiKey?: string,
    videoSource?: MediaStreamTrack
  ): Thunk =>
  (dispatch, getState): void => {
    if (conversation && sessionId && token && apiKey) {
      dispatch(resetState())
      dispatch(getDevices())
      session = OT.initSession(apiKey, sessionId)

      session.on("streamCreated", event => {
        dispatch(initSubscriber(event.stream, conversation))

        dispatch(
          updateTokboxHasVideo({
            subscriber: event.stream.hasVideo
          })
        )
        dispatch(startCallTimer())
      })

      session.on("sessionReconnecting", () => {
        dispatch(setSessionReconnecting(true))
      })

      session.on("sessionReconnected", () => {
        dispatch(setSessionReconnecting(false))
      })

      session.on("sessionDisconnected", () => {
        dispatch(setSessionReconnecting(false))
        session!.off()
        session = undefined
        publisher = undefined
        dispatch(
          setTokboxConnectTimeout(
            setTimeout(() => {
              if (getState().tokbox.callState === "active") {
                dispatch(endCall(conversation, "no_connect"))
              }
            }, 10000)
          )
        )
      })

      let connectionCount = 0

      session.on("connectionCreated", () => {
        connectionCount++
        if (connectionCount > 2) dispatch(endCall(conversation, "error"))
      })

      session.on("connectionDestroyed", () => {
        connectionCount--
        if (getState().tokbox.callState === "active") {
          dispatch(endCall(conversation, "error"))
        }
      })

      // The patient disconnected from the stream
      // This is (temporarily) removed because it causes an issue in the ios app
      // where the app will destroy the stream the first time the app/patients turns on video in a call.
      // With this that ends the call.
      // We should revisit this when ios has their code review with tokbox
      /*     session.on("streamDestroyed", () => {
      if (getState().tokbox.callState === "active") {
        dispatch(endCall(conversation, "error"))
      }
    }) */

      session.on("streamPropertyChanged", event => {
        if (event.changedProperty === "hasVideo") {
          if ((event.stream as any).publisher) {
            dispatch(updateTokboxHasVideo({ publisher: event.newValue }))
          } else {
            dispatch(updateTokboxHasVideo({ subscriber: event.newValue }))
          }
        }

        const { hasVideo, expanded } = getState().tokbox
        if (!hasVideo.publisher && !hasVideo.subscriber && expanded) dispatch(toggleLocalExpanded())
      })

      publisher = dispatch(initPublisher(conversation, 0, videoSource))

      session.connect(token, (error: any) => {
        if (error) {
          dispatch(handleError(conversation, error))
        } else {
          session!.publish(publisher!, (error: any) => dispatch(handleError(conversation, error)))
        }
      })
    }
  }

export const tearDownTokbox = (): Thunk => dispatch => {
  dispatch(resetState())
  session?.disconnect()
}
