import { useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { Button, Dialog, StaffHeading3, StaffHeading4, StaffLabelTextS } from "@doktor-se/bones-ui"
import { AssignedConversation, JournalSection, PaymentRequired } from "@doktor-se/bones-ui/dist/web-shared/types"

import { closeConversation, saveJournalNote, setMetadataOnConversation } from "api"
import { featureFlags } from "config"
import { checkDeclineCall } from "lib/conversations"
import { useAppDispatch, useAppSelector } from "lib/hooks"

import CardExemptContent from "./components/CardExemptContent/CardExemptContent"
import ConfirmCloseWithoutSaving from "./components/ConfirmCloseWithoutSaving/ConfirmCloseWithoutSaving"
import CloseOptionsContent from "components/CloseDialog/components/CloseOptionsContent/CloseOptionsContent"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"
import { useLocalizeSectionNames } from "pages/dashboard/components/PatientDetailsPanel/components/JournalForm/utils"

import styles from "./CloseReviewJournalDialog.module.scss"

type DialogState = "review" | "no-save"

interface CloseDialogProps {
  isOpen: boolean
  onClose: () => void
  conversation: AssignedConversation
  offset?: Offset
}

const CloseReviewJournalDialog = ({ isOpen, onClose, conversation, offset }: CloseDialogProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const defaultRole = useAppSelector(state => state.auth.defaultRole)

  const createdByStaff = conversation.createdById !== conversation.patientId
  const diagnosisCodes = useAppSelector(state => state.conversations.webdocDiagnosisCodes)

  const hasPaymentTag =
    featureFlags.has("close_tag") &&
    !!conversation.metadata?.internal &&
    createdByStaff &&
    !conversation.metadata?.paymentRequired

  const initialState: DialogState = "review"

  const localizeSectionNames = useLocalizeSectionNames(intl)

  const [dialogState, setDialogState] = useState<DialogState>("review")
  const [paymentTag, setPaymentTag] = useState<PaymentRequired>()
  const [closeOptionId, setCloseOptionId] = useState<string>("")
  const hasJournalNote = conversation.journalNote !== undefined
  const sections = hasJournalNote && conversation.journalNote?.data.sections
  const codes = useMemo(() => {
    return hasJournalNote ? conversation.journalNote?.data.codes : []
  }, [conversation.journalNote?.data.codes, hasJournalNote])

  const mappedDiagnosisCodes = useMemo(() => {
    return codes?.map(c => {
      const fullCode = diagnosisCodes?.find(dc => dc.value === c)
      return fullCode
    })
  }, [codes, diagnosisCodes])

  const mappedSections = useMemo(() => {
    const updatedSections = localizeSectionNames.map(localizedSection => {
      const existingSection = conversation?.journalNote?.data?.sections?.find(
        section => section.title === localizedSection.title
      )
      return {
        ...localizedSection,
        body: existingSection ? existingSection.body : ""
      }
    })
    return updatedSections
  }, [conversation?.journalNote?.data?.sections, localizeSectionNames])

  const onCloseDialog = () => {
    setPaymentTag(undefined)
    setCloseOptionId("")
    setDialogState(initialState)
    onClose()
  }

  const onConfirm = () => {
    if (dialogState === "review") {
      setDialogState("no-save")
      return
    }
    const reason = checkDeclineCall(conversation)
    const metadata = { closingStaffRole: defaultRole, ...(paymentTag && { paymentRequired: paymentTag }) }
    dispatch(setMetadataOnConversation(conversation.id, metadata))
    dispatch(
      closeConversation({
        conversation,
        closeOptionId: closeOptionId || undefined,
        reason
      })
    )
    onCloseDialog()
  }

  const onConfirmAndSendJournal = (sections: JournalSection[] | undefined, codes: string[] | undefined) => {
    const reason = checkDeclineCall(conversation)
    const metadata = { closingStaffRole: defaultRole, ...(paymentTag && { paymentRequired: paymentTag }) }
    dispatch(setMetadataOnConversation(conversation.id, metadata))
    sections && dispatch(saveJournalNote(conversation.id, sections, true, codes))
    dispatch(
      closeConversation({
        conversation,
        closeOptionId: closeOptionId || undefined,
        reason
      })
    )
    onCloseDialog()
  }

  return (
    <Dialog
      fixedWidth
      isOpen={isOpen}
      offset={offset && { top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onCloseDialog}
      disableHorizontalScroll
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}>
      {dialogState === "review" && (
        <StaffHeading3 className={styles.heading}>{intl.formatMessage({ id: "control.close" })}</StaffHeading3>
      )}
      <div className={styles.content}>
        {dialogState === "review" && (
          <>
            {!hasPaymentTag && (
              <>
                <CardExemptContent paymentTag={paymentTag} setPaymentTag={setPaymentTag} />
                <div className={styles.divider} style={{ marginTop: "16px" }} />
              </>
            )}
            <StaffHeading4>
              {intl.formatMessage({
                id: "label.close.level_of_care"
              })}
            </StaffHeading4>
            <CloseOptionsContent
              showHeading={false}
              categoryId={conversation.categoryId}
              closeOptionId={closeOptionId}
              setCloseOptionId={setCloseOptionId}
            />
            <div className={styles.divider} />
            <>
              <StaffHeading4 className={styles.summaryHeader}>
                {intl.formatMessage({
                  id: "label.close.journal.summary"
                })}
              </StaffHeading4>
              <div className={styles.sections}>
                {mappedDiagnosisCodes && (
                  <div className={styles.codes}>
                    <StaffLabelTextS className={styles.sectionLabel}>
                      {intl.formatMessage({ id: "label.close.journal.codes" })}
                    </StaffLabelTextS>
                    {mappedDiagnosisCodes?.map(code => <div className={styles.codeSection}>{code?.label}</div>)}
                  </div>
                )}
                {mappedSections &&
                  mappedSections.map(item => (
                    <>
                      <StaffLabelTextS className={styles.sectionLabel}>
                        {intl.formatMessage({
                          id: item.title
                        })}
                      </StaffLabelTextS>
                      <div className={styles.textSection}>
                        {item.type === "checkbox" ? item.body || "Nej" : item.body}
                      </div>
                    </>
                  ))}
              </div>
            </>
          </>
        )}
        {dialogState === "no-save" && <ConfirmCloseWithoutSaving />}
      </div>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          color="primary"
          onPress={() => sections && onConfirmAndSendJournal(sections, codes)}
          fullWidth>
          {intl.formatMessage({ id: "label.close.send" })}
        </Button>
        <Button variant="outline" color="primary" onPress={onConfirm} fullWidth>
          {intl.formatMessage({ id: "label.close" })}
        </Button>
      </div>
    </Dialog>
  )
}

export default CloseReviewJournalDialog
