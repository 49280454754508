import { useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import classNames from "classnames"

import { ICONS, StaffLabelTextS } from "@doktor-se/bones-ui"
import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { claimFirstAvailableConversation } from "api"
import audioFile from "audio/monitor-queue.mp3"
import { autoAssignFeature, featureFlags } from "config"
import { useStaffAmplitudeLogger } from "lib/amplitude/hooks"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setActiveInboxTab, setInboxNotification, toggleInboxPanelState } from "reducers/app"
import { categorySelectors } from "reducers/categories"
import { selectConversations } from "reducers/conversations/conversations.reducer"
import { selectPersonalQueue } from "reducers/queue/queue.reducer"
import { removeClaimConversationTimeout } from "reducers/selected"

import ConversationInQueue from "./components/ConversationInQueue/ConversationInQueue"
import ConversationList from "./components/ConversationList/ConversationList"
import NextConversation from "./components/NextConversation/NextConversation"
import Tab from "components/Tabs/Tab"
import TabContent from "components/Tabs/TabContent"
import Tabs from "components/Tabs/Tabs"
import TabsHeader from "components/Tabs/TabsHeader"

import styles from "./Inbox.module.scss"

type InboxTabs = "inbox" | "waiting"

export interface InboxProps {
  selectedConversationId?: string
  setConversation: (c: AssignedConversation) => void
  assignedConversations: AssignedConversation[]
  assignToUserBlocked: boolean
}

const autoAssignCountdownDurationInSeconds = 10

const Inbox = ({ selectedConversationId, assignedConversations, setConversation, assignToUserBlocked }: InboxProps) => {
  const dispatch = useAppDispatch()

  const userRoles = useAppSelector(state => state.auth.roles)
  const isReadyForAssignment = useAppSelector(state => state.app.isReadyForAssignment)
  const isQueuePingActive = useAppSelector(state => state.app.isQueuePingActive)
  const personalQueue = useAppSelector(selectPersonalQueue)
  const personalQueueKeys = useAppSelector(state => state.queue.personalQueueKeys)
  const categories = useAppSelector(categorySelectors.selectEntities)
  const isInboxPanelOpen = useAppSelector(state => state.app.isInboxPanelOpen)
  const activeTab = useAppSelector(state => state.app.activeInboxTab)
  const inboxNotification = useAppSelector(state => state.app.inboxNotification)
  const { active, waiting } = useAppSelector(selectConversations)

  const [activeCount, waitingCount] = [active.length, waiting.length]

  const intl = useIntl()
  const navigate = useNavigate()

  const autoAssignFeatureEnabled = autoAssignFeature(userRoles)
  const autoAssignInterval = useRef<ReturnType<typeof setInterval>>()
  const [autoAssignCountdown, setAutoAssignCountdown] = useState<number>()
  const [isClaiming, setIsClaiming] = useState(false)
  const prevPersonalQueueLength = usePrevious(personalQueue?.length)
  const audio = useRef<HTMLAudioElement>(null)

  const logAmplitude = useStaffAmplitudeLogger()

  const stopAutoAssignCountdown = () => {
    if (autoAssignInterval.current) {
      clearInterval(autoAssignInterval.current)
      autoAssignInterval.current = undefined
    }
    setAutoAssignCountdown(undefined)
  }

  useEffect(() => {
    if (autoAssignFeatureEnabled && isReadyForAssignment) {
      if (
        assignedConversations.length === 0 &&
        personalQueue &&
        personalQueue.length > 0 &&
        !autoAssignInterval.current &&
        !isClaiming
      ) {
        let countdown = autoAssignCountdownDurationInSeconds
        setAutoAssignCountdown(countdown)
        autoAssignInterval.current = setInterval(() => {
          if (countdown <= 1) {
            if (personalQueue.length > 0) {
              setIsClaiming(true)
              dispatch(claimFirstAvailableConversation(navigate)).then(claimed => {
                setTimeout(() => setIsClaiming(false), claimed ? 1000 : 1) // Avoid race condition with redux store state updates on successful claim
              })
            }
            stopAutoAssignCountdown()
          } else {
            setAutoAssignCountdown(--countdown)
          }
        }, 1000)
      } else if (assignedConversations.length > 0 || !personalQueue || personalQueue.length === 0) {
        stopAutoAssignCountdown()
      }
    }
  }, [
    dispatch,
    autoAssignFeatureEnabled,
    isReadyForAssignment,
    activeTab,
    assignedConversations,
    personalQueue,
    isClaiming,
    navigate
  ])

  useEffect(() => {
    if (!isReadyForAssignment) {
      stopAutoAssignCountdown()
      dispatch(removeClaimConversationTimeout())
    }
  }, [isReadyForAssignment, dispatch])

  useEffect(
    () => () => {
      stopAutoAssignCountdown()
      dispatch(removeClaimConversationTimeout())
    },
    [dispatch]
  )

  useEffect(() => {
    const currentPersonalQueueLength = personalQueue?.length
    if (isQueuePingActive && prevPersonalQueueLength === 0 && currentPersonalQueueLength === 1) {
      audio.current?.play()
    }
  }, [personalQueue, prevPersonalQueueLength, isQueuePingActive])

  useEffect(() => {
    if (activeTab === "inbox" && isInboxPanelOpen) dispatch(setInboxNotification(undefined))
  }, [activeTab, dispatch, isInboxPanelOpen])

  const showNextButton = useMemo(() => {
    if (!featureFlags.has("cherrypick")) {
      if (userRoles.includes("cherry_pick") && userRoles.includes("can_see_all_clinics_queues")) {
        return false
      }
      return !!personalQueueKeys?.length && activeTab === "inbox"
    }
  }, [activeTab, personalQueueKeys?.length, userRoles])

  const changeActiveTab = (tab: InboxTabs) => {
    if (tab === "inbox") {
      logAmplitude("inbox.tab_active.pressed")
    } else {
      logAmplitude("inbox.tab_postponed.pressed")
    }

    dispatch(setActiveInboxTab(tab))
  }

  const toggleInboxPanel = () => {
    logAmplitude("inbox.tabs_minimizer.pressed")
    dispatch(toggleInboxPanelState())
  }

  const conversationsListProps = {
    selectedConversationId,
    assignedConversations,
    setConversation,
    assignToUserBlocked
  }

  return (
    <div
      data-testid="conversationsListContainer"
      className={classNames(styles.container, { [styles.slideIn]: !isInboxPanelOpen })}>
      <Tabs
        activeTab={activeTab}
        setActiveTab={(id: string) => changeActiveTab(id as "inbox" | "waiting")}
        className={styles.tabsContainer}>
        <TabsHeader>
          <Tab
            id="inbox"
            title={`${intl.formatMessage({ id: "inbox.tab.active" })} (${activeCount})`}
            notification={inboxNotification}
          />
          <Tab id="waiting" title={`${intl.formatMessage({ id: "label.waiting" })} (${waitingCount})`} />
        </TabsHeader>

        <TabContent id="inbox">
          <ConversationList {...conversationsListProps}>
            <>
              <audio ref={audio}>
                <source src={audioFile} type="audio/mp3" />
                <track kind="captions" />
              </audio>

              {featureFlags.has("cherrypick") &&
                activeTab === "inbox" &&
                assignedConversations.length < 7 &&
                !!personalQueueKeys?.length &&
                personalQueue.length > 0 && (
                  <div data-testid="conversationsInQueue">
                    <StaffLabelTextS margin="30px 5px 10px">
                      {intl.formatMessage({ id: "conversations.queue.title" })}
                    </StaffLabelTextS>
                    {personalQueue.slice(0, 7 - assignedConversations.length).map(conversation => (
                      <ConversationInQueue
                        key={conversation.id}
                        conversation={conversation}
                        category={conversation.categoryId ? categories[conversation.categoryId] : undefined}
                      />
                    ))}
                  </div>
                )}

              {showNextButton && (
                <NextConversation
                  autoassignTimer={autoAssignCountdown}
                  autoassignFeature={autoAssignFeatureEnabled}
                  isBlocked={assignToUserBlocked}
                />
              )}
            </>
          </ConversationList>
        </TabContent>

        <TabContent id="waiting">
          <ConversationList {...conversationsListProps} />
        </TabContent>
      </Tabs>

      <button className={styles.togglePanel} onClick={toggleInboxPanel}>
        <ICONS.ChevronLeft className={classNames(styles.arrow, { [styles.rotateArrow]: !isInboxPanelOpen })} />
      </button>
    </div>
  )
}

export default Inbox
