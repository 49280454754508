import { useMemo } from "react"
import { useIntl } from "react-intl"

import { ICONS } from "@doktor-se/bones-ui"

import AutoSaveForm from "./components/autoSaveForm/AutoSaveForm"
import CopyAllButton from "./components/CopyAllButton"
import ConversationImages from "./components/images/ConversationImages"

import { useAppSelector } from "../../../../../../lib/hooks"
import { selectConversation } from "../../../../../../reducers/conversations/conversations.reducer"
import { useLocalizeSectionNames } from "./utils"

import styles from "./JournalForm.module.scss"

const JournalForm = () => {
  const intl = useIntl()
  const conversation = useAppSelector(selectConversation)

  const localizeSectionNames = useLocalizeSectionNames(intl)

  const { sections } = useAppSelector(
    state =>
      state.selected.localConversationStates
        ?.filter(item => item.conversationId === conversation?.id)
        .map(item => item.state.journalDraft?.data)
        .pop() || { sections: localizeSectionNames } || { sections: [] }
  )
  const updatedData = useMemo(() => {
    const updatedSections = localizeSectionNames.map(localizedSection => {
      const existingSection = conversation?.journalNote?.data?.sections?.find(
        section => section.title === localizedSection.title
      )
      return {
        ...localizedSection,
        body: existingSection ? existingSection.body : ""
      }
    })
    const updatedCodes = conversation?.journalNote?.data?.codes || []
    return {
      ...conversation?.journalNote?.data,
      codes: updatedCodes,
      sections: updatedSections
    }
  }, [conversation?.journalNote?.data, localizeSectionNames])
  return (
    <>
      <div className={styles.container}>
        {conversation && (
          <AutoSaveForm
            conversationId={conversation.id}
            categoryId={conversation?.categoryId}
            initialJournalValues={updatedData}
          />
        )}
        {conversation && <ConversationImages conversation={conversation} />}
        <div className={styles.copyAllContainer}>
          <div className={styles.infoText}>
            <div className={styles.warningIcon}>
              <ICONS.WarningCircle height={20} width={20} />
            </div>
            <div className={styles.warningText}>{intl.formatMessage({ id: "journal.copy.warning" })}</div>
          </div>
          <CopyAllButton categoryId={conversation?.categoryId} sections={sections} />
        </div>
      </div>
    </>
  )
}

export default JournalForm
