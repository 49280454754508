import { useIntl } from "react-intl"

import { ICONS, StaffHeading3, StaffLabelTextS } from "@doktor-se/bones-ui"

import styles from "./ConfirmCloseWithoutSaving.module.scss"

const ConfirmCloseWithoutSaving = () => {
  const intl = useIntl()

  return (
    <div className={styles.textContainer}>
      <ICONS.ErrorCircle />
      <StaffHeading3>
        {intl.formatMessage({
          id: "label.close.journal.without_saving"
        })}
      </StaffHeading3>
      <StaffLabelTextS>
        {intl.formatMessage({
          id: "journal.webdoc.close.without_saving_warning"
        })}
      </StaffLabelTextS>
    </div>
  )
}

export default ConfirmCloseWithoutSaving
