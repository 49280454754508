import { useIntl } from "react-intl"

import { CopyToClipboard } from "@doktor-se/bones-ui"
import { JournalSection } from "@doktor-se/bones-ui/dist/web-shared/types"

import { logAmplitudeEvent } from "lib/amplitude/amplitude"

import styles from "./CopyAllButton.module.scss"

interface CopyAllButtonProps {
  categoryId?: number
  sections: JournalSection[]
}

const CopyAllButton = ({ categoryId, sections }: CopyAllButtonProps): JSX.Element => {
  const intl = useIntl()

  const onCopy = () => {
    logAmplitudeEvent("carealot.chat_journal.copy_note_pressed", {
      category_id: categoryId
    })
  }

  return (
    <div className={styles.copyAllButton}>
      <div className={styles.copyAllText}>Kopiera allt</div>
      <CopyToClipboard
        onCopy={onCopy}
        copyText={sections.map(section => `${intl.formatMessage({ id: section.title })}\n${section.body}`).join("\n")}
        confirmationText={intl.formatMessage({ id: "copied.confirmation" })}
      />
    </div>
  )
}

export default CopyAllButton
