import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { useSelectedConversationAmplitudeLogger } from "lib/amplitude/hooks"

import Tab from "../../../../../components/Tabs/Tab"
import TabContent from "../../../../../components/Tabs/TabContent"
import TabsHeader from "../../../../../components/Tabs/TabsHeader"
import JournalForm from "../components/JournalForm/JournalForm"
import Tabs from "components/Tabs/Tabs"

import PatientInfo from "../../../../patientInfo/PatientInfo"

import styles from "./PatientInfoTabs.module.scss"

type InfoTabs = "patientInfo" | "journal"

interface PatientInfoTabsProps {
  setDoctorJournalOpen: Function
}

const PatientInfoTabs = ({ setDoctorJournalOpen }: PatientInfoTabsProps) => {
  const [activeTab, setActiveTab] = useState<InfoTabs>("patientInfo")
  const intl = useIntl()

  const logAmplitude = useSelectedConversationAmplitudeLogger()

  useEffect(() => {
    activeTab === "journal" ? setDoctorJournalOpen(true) : setDoctorJournalOpen(false)
  }, [activeTab, setDoctorJournalOpen])

  const changeActiveTab = (tab: InfoTabs) => {
    if (tab === "patientInfo") {
      logAmplitude("inbox.patient_info.pressed")
    } else {
      logAmplitude("inbox.journal_note.pressed")
    }
    setActiveTab(tab)
  }

  return (
    <Tabs
      activeTab={activeTab}
      setActiveTab={(id: string) => changeActiveTab(id as "patientInfo" | "journal")}
      className={classNames(styles.tabsContainer, activeTab === "patientInfo" ? styles.narrow : styles.wide)}>
      <TabsHeader>
        <Tab id="patientInfo" title={`${intl.formatMessage({ id: "patientinfo.tab.info" })}`} />
        <Tab id="journal" title={`${intl.formatMessage({ id: "patientinfo.tab.journal" })}`} />
      </TabsHeader>

      <TabContent id="patientInfo">
        <PatientInfo />
      </TabContent>
      <TabContent id="journal">
        <JournalForm />
      </TabContent>
    </Tabs>
  )
}

export default PatientInfoTabs
