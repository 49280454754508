import { ReactNode, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { ICONS } from "@doktor-se/bones-ui"

import { useAppSelector } from "lib/hooks"
import { patientStatusSelector } from "reducers/patients/status.reducer"

import styles from "./PatientOnlineStatus.module.scss"

const Tooltip = ({ children }: { children: ReactNode }) => <div className={styles.tooltip}>{children}</div>

interface PatientOnlineStatusProps {
  patientId: string
}

const PatientOnlineStatus = ({ patientId }: PatientOnlineStatusProps): JSX.Element => {
  const intl = useIntl()
  const status = useAppSelector(state => patientStatusSelector(state, patientId))
  const [showTooltip, setShowTooltip] = useState(false)
  const delayTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = () => {
    delayTimeout.current = setTimeout(() => {
      setShowTooltip(true)
    }, 500)
  }

  const handleMouseLeave = () => {
    if (delayTimeout.current) {
      clearTimeout(delayTimeout.current)
    }
    setShowTooltip(false)
  }

  const tooltipValue = useMemo(() => {
    if (status === "online") {
      return intl.formatMessage({ id: "tooltip.patient.online" })
    } else if (status === "offline") {
      return intl.formatMessage({ id: "tooltip.patient.offline" })
    }
    return ""
  }, [intl, status])

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames(styles.dot, {
        [styles.online]: status === "online",
        [styles.offline]: status === "offline"
      })}>
      {showTooltip && <Tooltip>{tooltipValue}</Tooltip>}
      {status === "online" && (
        <ICONS.Check color={"var(--oncolor-onsuccess-50)"} className={styles.check} width={"14px"} height={"14px"} />
      )}
      {status === "offline" && (
        <ICONS.Close color={"var(--oncolor-onsuccess-50)"} className={styles.close} width={"8px"} height={"8px"} />
      )}
    </div>
  )
}

export default PatientOnlineStatus
