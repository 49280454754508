import { useCallback } from "react"

import { useAppSelector } from "lib/hooks"
import { selectConversation } from "reducers/conversations/conversations.reducer"

import { logAmplitudeEvent } from "./amplitude"
import { AmplitudeEventMap, AmplitudeStaffEventBase } from "./events"

const useUserInfo = (): AmplitudeStaffEventBase => {
  const user = useAppSelector(state => state.auth.user)

  return {
    staff_id: user?.id,
    clinic_id: user?.data?.clinic
  }
}

// This hook logs to amplitude an event that contains staff_id and clinic_id
export const useStaffAmplitudeLogger = () => {
  const userInfo = useUserInfo()

  const logEvent = useCallback(
    (eventType: keyof AmplitudeEventMap, additionalEventProperties?: Record<string, any>) => {
      logAmplitudeEvent(eventType, { ...userInfo, ...additionalEventProperties })
    },
    [userInfo]
  )

  return logEvent
}

export const useSelectedConversationAmplitudeLogger = () => {
  const userInfo = useUserInfo()
  const conversation = useAppSelector(selectConversation)

  const logEvent = useCallback(
    (eventType: keyof AmplitudeEventMap) => {
      logAmplitudeEvent(eventType, { ...userInfo, conversation_id: conversation?.id })
    },
    [conversation?.id, userInfo]
  )

  return logEvent
}
