import { useState } from "react"
import { useIntl } from "react-intl"

import { ICONS } from "@doktor-se/bones-ui"
import { IconButton } from "@doktor-se/bones-ui/dist/web-shared/components"

import { handleFlagged } from "api/error/handler"
import { useSelectedConversationAmplitudeLogger } from "lib/amplitude/hooks"
import { useAppDispatch } from "lib/hooks"

import ActionPill from "components/topmenu/ReportBug/ActionPill/ActionPill"

import ConfirmReportSent from "./ConfirmReportSent"
import ReportBugDialog from "./ReportBugDialog"

import styles from "./ReportBug.module.scss"

type Event = React.MouseEvent<HTMLElement, MouseEvent>

const getPos = (e: Event) => {
  const { left, top } = e.currentTarget.getBoundingClientRect()
  return { left, top }
}

interface ReportBugProps {
  conversationId?: string
  customOffset?: { left: number; top: number }
  buttonType: "pill" | "icon"
}

const ReportBug = ({ conversationId, customOffset, buttonType = "pill" }: ReportBugProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [showFlagModal, setShowFlagModal] = useState(false)
  const [offset, setOffset] = useState(customOffset || { left: 0, top: 0 })
  const [showFlagSentModal, setShowFlagSentModal] = useState(false)
  const logEvent = useSelectedConversationAmplitudeLogger()
  const onClick = (evt: Event) => {
    if (buttonType === "icon") {
      logEvent("conversation.technical_issues.pressed")
    }
    setShowFlagModal(true)
    !customOffset && setOffset(getPos(evt))
  }
  return (
    <>
      {buttonType === "pill" && (
        <div className={styles.actions}>
          <ActionPill
            Icon={ICONS.Flag}
            text={intl.formatMessage({ id: "flag.report.button" })}
            onClick={onClick}
            colored
          />
        </div>
      )}
      {buttonType === "icon" && (
        <div className={styles.iconButton}>
          <IconButton
            iconSize={20}
            labelSize={14}
            onClick={onClick}
            Icon={ICONS.Flag}
            label={intl.formatMessage({ id: "flag.report.button" })}
          />
        </div>
      )}
      <ReportBugDialog
        isOpen={showFlagModal}
        onClose={() => setShowFlagModal(false)}
        flag={(flagBody, flagSubject) => {
          dispatch(handleFlagged(flagBody, flagSubject, conversationId))
          setShowFlagModal(false)
          setShowFlagSentModal(true)
        }}
        offset={offset}
      />
      <ConfirmReportSent isOpen={showFlagSentModal} onClose={() => setShowFlagSentModal(false)} offset={offset} />
    </>
  )
}

export default ReportBug
