import { ConversationCustomerMetadataUpdatedEvent } from "@doktor-se/bones-ui/dist/web-shared/types"

import { fetchAssignedConversation } from "api/conversations/conversations.api"
import { Thunk } from "lib/hooks"
import { updateConversationCustomerMetadata } from "reducers/conversations/conversations.reducer"

export const handleCustomerMetadataUpdate =
  (event: ConversationCustomerMetadataUpdatedEvent): Thunk =>
  (dispatch, getState) => {
    if (event.customerMetadata.encryption) {
      dispatch(fetchAssignedConversation(event.conversationId))
    }

    let retries = 0
    const maxRetries = 10
    const interval = setInterval(() => {
      const conversationExists = getState().conversations.conversations.ids.includes(event.conversationId)
      if (conversationExists) {
        clearInterval(interval)
        dispatch(updateConversationCustomerMetadata(event))
      } else if (retries >= maxRetries) {
        clearInterval(interval)
      }

      retries += 1
    }, 2000)
  }
