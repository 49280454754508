import { useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import classnames from "classnames"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"

import { Texts } from "@doktor-se/bones-ui/dist/web-shared/components/feed/types"
import { AssignedConversation, AssignmentMessageFiltered } from "@doktor-se/bones-ui/dist/web-shared/types"

import {
  checkPermissions,
  makeBookedConversationActive,
  openBookedConversation,
  reassignConversation,
  snoozeConversation,
  startCall,
  startCallWithActive,
  updateCategory
} from "api"
import { featureFlags, requiredActionBeforeSnooze } from "config"
import { logAmplitudeEvent } from "lib/amplitude/amplitude"
import { checkDeclineCall } from "lib/conversations"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setDialNumberOffset, setShowDialNumber } from "reducers/app"
import { selectBookingById } from "reducers/booking"
import { categorySelectors } from "reducers/categories"

import RevisitsDialog from "../../../../components/Revisits/RevisitsDialog"
import FeedHeader from "./components/FeedHeader/FeedHeader"
import CloseDialog from "components/CloseDialog/CloseDialog"
import CloseReviewJournalDialog from "components/CloseReviewJournalDialog/CloseReviewJournalDialog"
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog"
import PhysicalToDigitalDialog from "components/PhysicalToDigitalDialog/PhysicalToDigitalDialog"
import ReassignDialog from "components/ReassignDialog/ReassignDialog"
import SnoozeDialog, { SnoozeTime } from "components/SnoozeDialog/SnoozeDialog"
import TopMenu from "components/topmenu/TopMenu"
import ToQueueDialog from "components/ToQueueDialog/ToQueueDialog"

import styles from "./ConversationControls.module.scss"

dayjs.extend(advancedFormat)

export interface Offset {
  left: number
  top: number
}

const initShow = {
  confirm: false,
  close: false,
  snooze: false,
  reassign: false,
  toQueue: false,
  callNumber: false,
  blocked: false,
  revisits: false
}

interface ConversationControlsProps {
  conversations: AssignedConversation[]
  conversation: AssignedConversation
  texts: Texts
}

const ConversationControls = ({ conversations, conversation, texts }: ConversationControlsProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const navigate = useNavigate()
  const authorized = useAppSelector(state => state.auth.authorized)
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const categories = useAppSelector(categorySelectors.selectAll)
  const conversationBooking = useAppSelector(selectBookingById(conversation.metadata?.bookingId))
  const activeInboxTab = useAppSelector(state => state.app.activeInboxTab)
  const inboxPanelOpen = useAppSelector(state => state.app.isInboxPanelOpen)
  const category = useAppSelector(state => categorySelectors.selectById(state, conversation.categoryId || 0))
  const activeLanguage = useAppSelector(state => state.app.language)

  const [show, setShow] = useState(initShow)
  const [offset, setOffset] = useState({
    left: 0,
    top: 0
  })

  const [confirm, setConfirm] = useState({ msg: "", btn: "", func: () => {} })
  const conversationWaiting = activeInboxTab === "waiting"

  const blockSnooze = useMemo(() => {
    let actionBeforeSnooze = requiredActionBeforeSnooze(defaultRole)
    if (!actionBeforeSnooze) return false

    const assignmentMessages = conversation.messages?.filter(
      message => message.type === "assignment"
    ) as AssignmentMessageFiltered[]
    const lastAssignmentMessage = assignmentMessages?.[assignmentMessages.length - 1]
    if (!!lastAssignmentMessage?.data.lastAssignedStaffId) return false

    const lastAssignmentMessageIndex = conversation.messages?.findIndex(m => m.id === lastAssignmentMessage?.id)
    const messagesSinceLastAssignment = conversation.messages?.slice(lastAssignmentMessageIndex)

    return !messagesSinceLastAssignment
      ?.filter(item => item.createdById === conversation.assignedStaffId)
      .some(message => actionBeforeSnooze?.includes(message.type))
  }, [defaultRole, conversation.messages, conversation.assignedStaffId])

  const hasJournalNote =
    featureFlags.has("webdoc") &&
    featureFlags.has("webdoc_journal_note_send") &&
    conversation?.journalNote?.data?.sections &&
    activeLanguage === "sv"

  const CloseDialogComponent = hasJournalNote ? CloseReviewJournalDialog : CloseDialog

  // Execute
  const startCallConfirmed = () => {
    const active = conversations.filter(e => ["active", "outgoing"].includes(e.callState))
    if (active.length > 0) {
      const reason = checkDeclineCall(active[0])
      dispatch(checkPermissions(() => dispatch(startCallWithActive(conversation, "dialing", active[0], reason))))
    } else {
      dispatch(checkPermissions(() => dispatch(startCall(conversation, "dialing"))))
    }
    setShow({ ...show, confirm: false })
  }

  // ConfirmDialog
  const connectCallCheck = (newOffset: Offset) => {
    setShow({
      ...initShow,
      confirm: true
    })
    setOffset(newOffset)
    setConfirm({
      func: () => startCallConfirmed(),
      msg: intl.formatMessage({ id: "control.call.active" }),
      btn: intl.formatMessage({ id: "label.continue" })
    })
  }

  const startCallCheck = (newOffset: Offset) => {
    const active = conversations.filter(e => ["active", "outgoing"].includes(e.callState))
    switch (conversation.callState) {
      case "inactive":
        if (active.length > 0) {
          connectCallCheck(newOffset)
        } else {
          startCallConfirmed()
        }
        break
      default:
        break
    }
  }

  const snoozeCheck = (snoozeTime: SnoozeTime) => {
    setShow({ ...show, confirm: true, snooze: false })
    setConfirm({
      func: () => snoozeConfirmed(snoozeTime.date()),
      msg:
        conversation.callState !== "inactive"
          ? intl.formatMessage({ id: "control.call.active.snooze" })
          : intl.formatMessage({ id: "control.snooze" }, { label: snoozeTime.label, time: snoozeTime.display() }),
      btn: intl.formatMessage({ id: "label.snooze" })
    })
  }

  const toInboxCheck = (newOffset: Offset) => {
    setShow({
      ...initShow,
      confirm: true
    })
    setOffset(newOffset)

    let message = intl.formatMessage({ id: "control.send.inbox" })
    if (conversationBooking)
      message = `${intl.formatMessage(
        { id: "control.send.inbox.booking" },
        { time: dayjs(conversationBooking.start).format("HH:mm") }
      )} ${message}`

    setConfirm({
      func: () => {
        resolveInboxConfirmAction()()
        setShow({ ...show, confirm: false })
        navigate("/")
      },
      msg: message,
      btn: intl.formatMessage({ id: "action.send.inbox" })
    })
  }

  const resolveInboxConfirmAction = () => {
    if (conversation.snoozedUntil) {
      return () => snoozeConfirmed()
    }
    if (conversation.metadata?.bookingId) {
      return () => bookedConversationActivationConfirmed()
    }
    return () => unpostponeConfirmed()
  }

  const snoozeConfirmed = (date?: string) => {
    const reason = checkDeclineCall(conversation)
    dispatch(snoozeConversation(conversation, date, reason))
  }

  const bookedConversationActivationConfirmed = () => {
    if (conversation.state === "booked") {
      dispatch(openBookedConversation(conversation.id))
    } else {
      dispatch(makeBookedConversationActive(conversation.id))
    }
  }

  const unpostponeConfirmed = () => {
    dispatch(reassignConversation({ conversation, assignedId: conversation.assignedStaffId! }))
  }

  const closeCheck = (newOffset: Offset) => {
    setShow({ ...initShow, close: true })
    setOffset(newOffset)
  }

  // Choose
  const callNumber = (newOffset: Offset) => {
    const calls = conversations.filter(e => ["active", "outgoing"].includes(e.callState))
    if (conversation.callState === "inactive" && !calls.length) {
      dispatch(setShowDialNumber(true))
      dispatch(setDialNumberOffset(newOffset))
    }
  }

  const revisits = (newOffset: Offset) => {
    setShow({
      ...initShow,
      revisits: !show.revisits
    })
    logAmplitudeEvent("carealot.book_followup.feature_cta_pressed", {
      category_id: conversation?.categoryId,
      category_name: category?.name
    })
    setOffset(newOffset)
  }

  const snooze = (newOffset: Offset) => {
    setShow({
      confirm: false,
      close: false,
      snooze: !show.snooze,
      reassign: false,
      toQueue: false,
      callNumber: false,
      blocked: false,
      revisits: false
    })
    setOffset(newOffset)
  }

  const reassign = (newOffset: Offset) => {
    setShow({
      ...initShow,
      reassign: !show.reassign
    })
    setOffset(newOffset)
  }

  const toQueue = (newOffset: Offset) => {
    setShow({
      ...initShow,
      toQueue: !show.toQueue
    })
    setOffset(newOffset)
  }

  const closeModal = (
    modal: "confirm" | "close" | "reassign" | "toQueue" | "snooze" | "callNumber" | "blocked" | "revisits"
  ) => {
    setShow(s => ({ ...s, [modal]: false }))
  }

  return (
    <div className={styles.controlsContainer}>
      <div
        className={classnames(styles.feedHeaderContainer, { [styles.feedHeaderContainerInboxOpen]: inboxPanelOpen })}>
        <FeedHeader
          conversation={conversation}
          categories={categories}
          updateCategory={categoryId => dispatch(updateCategory(categoryId, conversation.id))}
          texts={texts}
        />
      </div>
      <div className={classnames(styles.topMenuContainer, { [styles.topMenuContainerInboxOpen]: inboxPanelOpen })}>
        <TopMenu
          authorized={authorized}
          startCall={startCallCheck}
          toQueue={toQueue}
          reassign={reassign}
          isBooking={!!conversationBooking}
          conversationToInbox={toInboxCheck}
          snoozeConversation={snooze}
          closeConversation={closeCheck}
          waiting={conversationWaiting}
          callNumber={callNumber}
          revisits={revisits}
        />
      </div>
      {show.confirm && (
        <ConfirmDialog
          isOpen={show.confirm}
          offset={{ top: `${offset.top}px`, left: `${offset.left}px` }}
          message={confirm.msg}
          confirmText={confirm.btn}
          onConfirm={confirm.func}
          onCancel={() => closeModal("confirm")}
        />
      )}
      {show.close && (
        <CloseDialogComponent
          isOpen={show.close}
          offset={offset}
          onClose={() => closeModal("close")}
          conversation={conversation}
        />
      )}
      {show.snooze && (
        <SnoozeDialog
          isOpen={show.snooze}
          offset={offset}
          isBlocked={blockSnooze}
          onSnooze={snoozeCheck}
          onClose={() => closeModal("snooze")}
        />
      )}
      {show.reassign && (
        <ReassignDialog
          isOpen={show.reassign}
          offset={offset}
          conversation={conversation}
          onClose={() => closeModal("reassign")}
        />
      )}
      {show.toQueue &&
        (featureFlags.has("physical_to_digital") ? (
          <PhysicalToDigitalDialog
            isOpen={show.toQueue}
            offset={offset}
            conversation={conversation}
            onClose={() => closeModal("toQueue")}
          />
        ) : (
          <ToQueueDialog
            isOpen={show.toQueue}
            offset={offset}
            conversation={conversation}
            onClose={() => closeModal("toQueue")}
          />
        ))}
      {show.revisits && (
        <RevisitsDialog
          isOpen={show.revisits}
          offset={offset}
          conversation={conversation}
          onClose={() => closeModal("revisits")}
        />
      )}
    </div>
  )
}

export default ConversationControls
