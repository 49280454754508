import { useIntl } from "react-intl"

import { Radio, RadioGroup, StaffHeading4 } from "@doktor-se/bones-ui"
import { PaymentRequired } from "@doktor-se/bones-ui/dist/web-shared/types"

import styles from "./CardExemptContent.module.scss"

const options: Array<{ value: Exclude<PaymentRequired, "no_show" | undefined>; translationKey: string }> = [
  {
    value: "card_exempt",
    translationKey: "label.yes"
  },
  {
    value: "payment_accepted",
    translationKey: "label.no"
  },
  {
    value: "not_relevant",
    translationKey: "label.na"
  }
]

interface CardExemptContentProps {
  paymentTag?: PaymentRequired
  setPaymentTag: (paymentTag: PaymentRequired) => void
}

const CardExemptContent = ({ paymentTag, setPaymentTag }: CardExemptContentProps) => {
  const intl = useIntl()

  return (
    <div className={styles.container}>
      <StaffHeading4>
        {intl.formatMessage({
          id: "control.tag.close"
        })}
      </StaffHeading4>

      <RadioGroup
        label=""
        aria-label="Card Exemption"
        aria-labelledby=""
        value={paymentTag}
        onChange={value => {
          setPaymentTag(value as PaymentRequired)
        }}>
        {options.map(option => (
          <Radio key={option.value} value={option.value}>
            {intl.formatMessage({ id: option.translationKey })}
          </Radio>
        ))}
      </RadioGroup>
    </div>
  )
}

export default CardExemptContent
